import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {dimens, getCookie, setCookie, checkIssetLogin} from '../config/settings';

import { Form, Input, Button, Alert} from 'antd';

import { fetchData } from '../lib/apis';

export default class LoginView extends Component{
  constructor(props){
    super(props);
    this.state = {
        loading: false
    }

    if(checkIssetLogin()){
        this.props.history.push('/');
    }
  }
  onSubmit = async (values) => {
    try{
        this.setState({ loading: true, error: null});
        let response = await fetchData({
            url: 'api/v1/user/login',
            method: 'post',
            data: values
        });

        this.setState({ loading: false }, () => {
            setCookie(dimens.name_auth, btoa(response.token), 7);
            // setCookie(dimens.name_company, '', 0);
            
            let params = new URLSearchParams(this.props.history.location.search);
            let redirect_url = params.get("redirect_url");
            
            window.location.href = this.props.isAppNative ? "/chat" : (redirect_url || "/");
        });
    } catch(err){
        this.setState({ loading: false, error: err.message || 'Lỗi đăng nhập' });
    }
  }
  render(){
    return(
        <div id="app-container">
            <div id="login-view" className="tab-login" style={{paddingTop: `${this.props.statusBarHeight}px`}}>
                <div id="side-caption-panel" className="caption-panel" style={{paddingRight: "20px", paddingLeft: "20px"}}>
                    <div id="sidepanel-title" className="panel-title">Đăng nhập</div>
                </div>
                <Form
                    onFinish={this.onSubmit}
                >
                    {
                        this.state.error ? <Alert message={this.state.error} type="error" style={{marginBottom: "15px"}}/> : null
                    }
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Tên đăng nhập bắt buộc' }]}
                    >
                        <Input size="large" placeholder="Tên đăng nhập"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                    >
                        <Input.Password size="large" placeholder="Mật khẩu"/>
                    </Form.Item>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Button htmlType="submit" type="primary" loading={this.state.loading}>Tiếp tục</Button>
                        <Link to="/register">
                            Đăng ký
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
  }
}
